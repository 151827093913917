<template>
        <div class="container" style="height:calc(100vh - 3.5rem)">
        
        <div class="row align-items-center justify-content-center h-100">
            
            
            <div class="col-sm col-lg-6 md-auto">

                <p class="h1 text-center text-white">
                    <i class="bi bi-cloud me-2"></i> FormaCloud
                </p>

                <h4 class="lead text-center text-white">Practical Work on Cloud is easier</h4>
                <br>
                
                <div class="alert alert-warning" role="alert">
                    <h1 class="text-center">
                        <i class="bi bi-exclamation-triangle-fill"></i>
                    </h1>
                    <h5 class="mb-3 text-center">Humm... Something went wrong</h5>
                    <p class="text-center mb-3">
                        Il semblerait que le service <code>API</code> de formacloud ne réponde pas
                    </p>

                    <h6 class="text-center">Causes possibles</h6>
                    <div class="alert bg-light text-center">
                        Service à l'arrêt<br/>
                        Mise à jour<br/>
                        Propagation DNS<br/>
                    </div>

                    <h6 class="mb-3 text-center">Que faire ?</h6>
                    <p class="text-center">
                        Attendez le retour du service sur cette page.<br/> Si le problème persiste envoyez un mail à <a href="mailto:support@formacloud.fr">support@formacloud.fr</a><br />ou contactez votre administrateur système.
                    </p>

                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                        </div>
                    </div>
                    
                </div>

            </div>

        </div>

    </div>
</template>

<script setup>

    import { onMounted } from 'vue';
    import axios from 'axios';
    import { useRouter } from 'vue-router';

    const router = useRouter();

    async function checkApi() {
        try{
            let version = (await axios.get("/api/version")).data;
            router.push("/")
        }
        catch(e){
            setTimeout( () => checkApi(), 10000);
        }
    }

    onMounted( async () => {
        await checkApi();
    })

</script>